export async function useInitAuth() {
  const user = useSupabaseUser()
  const client = useSupabaseClient()

  const { data: profile } = await useAsyncData(
    'user_profile',
    async () => {
      if (!user.value) return null
      return await client
        .from('profiles')
        .select()
        .eq('id', user.value.id)
        .single()
    },
    { watch: [() => user.value?.id] },
  )

  const userWithProfile = computed(() => {
    if (!user.value || !profile.value) return null
    return { ...user.value, profile: { ...profile.value.data } }
  })

  return { user: userWithProfile /*, login, logout */ }
}

export function provideAuth(authData: Awaited<ReturnType<typeof useInitAuth>>) {
  provide('auth', authData)
}

export function useAuth() {
  const auth = inject<Awaited<ReturnType<typeof useInitAuth>>>('auth')
  if (!auth) {
    throw new Error('No auth provider found!')
  }
  return auth
}
